
















































































































































































































































































import useAuthGroupSelect from '@/use/authGroupSelect'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({
        name: '',
      })
    }
  },

  setup(_, { root }) {
      const {
        eventPanelItems,
        participantItems,
        lecturersItems,
        companiesItems,
        abstractsItems,
        posterSessionItems,
        surveysItems,
        accessControl,
        printItems,
        reportsItems,
        infoItems,
        eventConfigItems,
        registrationModuleItems,
        accomodationModuleItems,
        additionalServicesModuleItems,
        abstractsModuleItems,
        partnersModuleItems,
        eventProgramModuleItems,
        posterSessionModuleItems,
        surveysModuleItems,
        autoMessagesModuleItems,
        companiesModuleItems,
        receptionModuleItems,
        siemensItems
      } = useAuthGroupSelect({ root })


    return {
      eventPanelItems,
      participantItems,
      lecturersItems,
      companiesItems,
      abstractsItems,
      posterSessionItems,
      surveysItems,
      accessControl,
      printItems,
      reportsItems,
      infoItems,
      eventConfigItems,
      registrationModuleItems,
      accomodationModuleItems,
      additionalServicesModuleItems,
      abstractsModuleItems,
      partnersModuleItems,
      eventProgramModuleItems,
      posterSessionModuleItems,
      surveysModuleItems,
      autoMessagesModuleItems,
      companiesModuleItems,
      receptionModuleItems,
      siemensItems
    }
  }
})
